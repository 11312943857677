// extracted by mini-css-extract-plugin
export var amount = "dl_bs7";
export var bottom = "dl_bc7";
export var custom = "dl_bx7";
export var defs = "dl_bB7";
export var donate = "dl_97";
export var donate__back = "dl_bm7";
export var donate__header = "dl_bg7";
export var donate__inner = "dl_bf7";
export var donate__lead = "dl_bl7";
export var hide = "dl_bp7";
export var list = "dl_br7";
export var list__item = "dl_bt7";
export var list__label = "dl_bw7";
export var only_copy = "dl_bk7";
export var s_amount = "dl_bh7";
export var s_custom = "dl_bj7";
export var s_wallet = "dl_bd7";
export var scroll = "dl_bb7";
export var shadow = "dl_bn7";
export var tokens = "dl_bv7";
export var wallet = "dl_bq7";
export var wallets = "dl_by7";
export var wallets__item = "dl_bz7";