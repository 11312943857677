import ScatterJS from '@scatterjs/core'
import ScatterEOS from '@scatterjs/eosjs'
import Eos from 'eosjs'

ScatterJS.plugins(new ScatterEOS())

const EosSendTokens = async (amount, to) => {
  if (typeof window !== 'undefined') {
    const network = ScatterJS.Network.fromJson({
      blockchain: 'eos',
      chainId:
        'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
      host: 'nodes.get-scatter.com',
      port: 443,
      protocol: 'https',
    })

    // eslint-disable-next-line consistent-return
    ScatterJS.connect('YourAppName', { network }).then((connected) => {
      if (!connected) return console.error('no scatter')

      const eos = ScatterJS.eos(network, Eos)

      // eslint-disable-next-line consistent-return
      ScatterJS.login().then((id) => {
        if (!id) return console.error('no identity')
        const account = ScatterJS.account('eos')
        const options = {
          authorization: [`${account.name}@${account.authority}`],
        }
        eos
          .transfer(
            account.name,
            `${to}`,
            `${amount} EOS`,
            account.name,
            options
          )
          .then((res) => {
            console.log('sent: ', res)
          })
          .catch((err) => {
            console.error('error: ', err)
          })
      })
    })
  }
}

export default EosSendTokens
