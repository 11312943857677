import web3 from 'web3'

const MetaMaskSendTokens = async (to, amount) => {
  if (typeof window !== 'undefined') {
    try {
      const provider = window.ethereum.providers
        ? window.ethereum.providers.find((p) => p.isMetaMask)
        : window.ethereum
      const value = web3.utils.toHex(web3.utils.toWei(`${amount}`, 'ether'))
      const newAccounts = await provider.request({
        method: 'eth_requestAccounts',
      })

      const result = await provider.request({
        method: 'eth_sendTransaction',
        params: [
          {
            from: newAccounts[0],
            to,
            value,
            // gasLimit: '0x5028',
            // gasPrice: '0x2540be400',
          },
        ],
      })
      console.log(result)
    } catch (error) {
      console.error(error)
    }
  }
}

export default MetaMaskSendTokens
