export default async (amount, destinationWallet) => {
  // user have to login by himself
  if (typeof window !== 'undefined') {
    if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
      try {
        const userAddress = window.tronWeb.defaultAddress.base58

        const USDTContract = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t'
        // const tx = await tronweb.transactionBuilder
        const { abi } = await window.tronWeb.trx.getContract(USDTContract)
        const contract = window.tronWeb.contract(abi.entrys, USDTContract)

        // user USDT balance
        const USDTbalance = await contract.methods.balanceOf(userAddress).call()
        console.log('balance:', USDTbalance.toString())

        // 1 usdt = 1000000
        const number = amount * 1000000
        const resp = await contract.methods
          .transfer(destinationWallet, number)
          .send()

        console.log('transfer:', resp)
      } catch (err) {
        console.log(err)
      }
    }
  } else {
    // suggest => pop to inform user to login to Tron link
  }
}
