/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Container, Button } from 'react-bootstrap'
import cn from 'classnames'
import gsap from 'gsap'

import useScroll from '~hooks/useScroll'
import Donate from '../Donate'
import Icon from '../Icon'

import partners from './img/partners.svg'

import { numberToStrWithSpaces } from './utils'

import * as s from './Hero.module.scss'

const Hero = () => {
  const [data, setData] = useState(null)

  // const GOAL = 200_000_000

  const USDC = 'usdc'
  const USDT = 'usdt'

  const TOKENS = ['btc', 'eth', 'dot', USDT, 'sol', USDC, 'hbar']

  const tokenSum =
    data &&
    data?.currencies.reduce((acc, { code, amount }) => {
      // const tokenFormat = code.includes(USDC) ? USDC
      switch (code) {
        case 'eth_usdc':
        case 'sol_usdc':
          return {
            ...acc,
            [USDC]: acc?.[USDC]
              ? // eslint-disable-next-line no-unsafe-optional-chaining
                acc?.[USDC] + amount
              : amount,
          }

        case 'trc20_usdt':
        case 'eth_usdt':
        case 'sol_usdt':
          return {
            ...acc,
            [USDT]: acc?.[USDT]
              ? // eslint-disable-next-line no-unsafe-optional-chaining
                acc?.[USDT] + amount
              : amount,
          }

        default:
          return {
            ...acc,
            [code]: amount,
          }
      }
    }, {})

  const { scrollY } = useScroll()

  const body =
    typeof document !== 'undefined' ? document.querySelector('body') : null

  const isScrolled =
    scrollY > 900 &&
    scrollY <
      (typeof document !== 'undefined'
        ? body.scrollHeight - window.outerHeight - 100
        : 0)

  const handleButton = () => {
    const scrollTo = document.querySelector('#donate').offsetTop - 90

    gsap.to(window, { scrollTo, ease: 'power2' })
  }

  useEffect(() => {
    fetch('https://api.aid-for-ukraine.io/v1/donation')
      .then(async (response) => {
        const json = await response.json()

        // check for error response
        // if (!response.ok) {
        //   // get error message from body or default to response statusText
        //   const error = (data && data.message) || response.statusText
        //   return Promise.reject(error)
        // }

        setData(json)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }, [])

  return (
    <div id="top" className={s.hero}>
      <Container className={s.hero__inner}>
        <div className={s.hero__content}>
          <h1>
            Help Ukraine with <span>crypto</span>, don’t leave us alone with the
            enemy
          </h1>
          <div className={s.progress}>
            <div className={cn('h3', s.progress__top)}>
              The community has already raised more than $60 million
              {/* <span>
                  $ {numberToStrWithSpaces(parseInt(data?.total_usd, 10))}
                </span> */}
            </div>
            <ul className={s.progress__tokens}>
              {tokenSum &&
                TOKENS.map(
                  (token) =>
                    tokenSum[token] && (
                      <li key={token}>
                        <Icon name={`t_${token}`} />
                        {numberToStrWithSpaces(
                          [USDT, USDC].includes(token)
                            ? parseInt(tokenSum[token], 10)
                            : tokenSum[token]
                        )}{' '}
                        {token}
                      </li>
                    )
                )}
            </ul>
          </div>
        </div>
        <Donate />
        <div className={s.powered}>
          <div>
            <strong>Aid For Ukraine</strong> is powered by{' '}
            <a href="https://everstake.one" target="_blank" rel="noreferrer">
              Everstake
            </a>{' '}
            and{' '}
            <a href="https://kuna.io" target="_blank" rel="noreferrer">
              Kuna
            </a>
          </div>
          <img src={partners} alt="Everstake ⨯ Kuna" />
        </div>
      </Container>
      <Button
        className={cn(s.button, { [s.show]: isScrolled })}
        onClick={handleButton}
      >
        Help Ukraine
      </Button>
    </div>
  )
}

export default Hero
