const DATA = [
  {
    label: 'Bitcoin',
    token: 'BTC',
    wallet: '357a3So9CbsNfBBgFYACGvxxS6tMaDoa1P',
  },
  {
    label: 'Ethereum',
    token: 'ETH',
    wallet: '0x165CD37b4C644C2921454429E7F9358d18A45e14',
    amounts: [0.05, 0.1, 0.25, 0.5, 1],
    customAmount: 2,
    wallets: [
      {
        label: 'Metamask',
        icon: 'metamask',
      },
    ],
  },
  {
    label: 'Tether',
    token: 'USDT',
    type: 'ERC-20',
    wallet: '0x165CD37b4C644C2921454429E7F9358d18A45e14',
  },
  {
    label: 'Tether',
    token: 'USDT',
    type: 'TRC-20',
    wallet: 'TEFccmfQ38cZS1DTZVhsxKVDckA8Y6VfCy',
  },
  // {
  //   label: 'Terra',
  //   token: 'LUNA',
  //   wallet: 'terra1n0nzlxfuk3e54vxktnskaze7c5g8h8vrxk8gyj',
  // },
  // {
  //   label: 'USD Coin',
  //   token: 'USDC',
  //   wallet: '0x00000000000000000000000000000000000000',
  //   amounts: [0.25, 0.5, 1, 3, 5],
  //   wallets: [
  //     {
  //       label: 'Metamask',
  //       icon: 'metamask',
  //     },
  //     {
  //       label: 'Metamask',
  //       icon: 'metamask',
  //     },
  //   ],
  // },
  {
    label: 'Solana',
    token: 'SOL',
    wallet: '66pJhhESDjdeBBDdkKmxYYd7q6GUggYPWjxpMKNX39KV',
    amounts: [1, 5, 10, 15, 25],
    customAmount: 50,
    wallets: [
      {
        label: 'Phantom',
        icon: 'phantom',
      },
      {
        label: 'Solflare',
        icon: 'solflare',
      },
      {
        label: 'Sollet (Extension)',
        icon: 'sollet',
      },
      {
        label: 'Solong',
        icon: 'solong',
      },
      {
        label: 'Slope',
        icon: 'slope',
      },
    ],
  },
  {
    label: 'Polkadot',
    token: 'DOT',
    wallet: '1x8aa2N2Ar9SQweJv9vsuZn3WYDHu7gMQu1RePjZuBe33Hv',
    amounts: [5, 25, 50, 75, 125],
    customAmount: 250,
    wallets: [
      {
        label: 'Polkadot',
        icon: 'dot',
      },
    ],
  },
  {
    label: 'NEAR Protocol',
    token: 'NEAR',
    wallet: 'ukraine',
  },
  {
    label: 'Cardano',
    token: 'ADA',
    wallet:
      'addr1qykgqgv5flwu4rm9wt2e69vuywj0fx368ylxqzqa7ep0ckm4zeuw67f9snkur4syrz2elqeyafx8wculjeearp3tz0wscz9350',
  },
  {
    label: 'Dogecoin',
    token: 'DOGE',
    wallet: 'DS76K9uJJzQjCFvAbpPGtFerp1qkJoeLwL',
  },
  {
    label: 'Algorand',
    token: 'ALGO',
    wallet: 'KYJCXKA3GB4QLCJKQN7QIKARL63U6BD3PSBAB7ZGJGCR4E4X54CYVUPLW4',
  },
  {
    label: 'Stellar',
    token: 'XLM',
    wallet: 'GD7ZVRSGHETEYLB4XUVFVGFWJKNDHORHAY72HZVXYFINUNZSRVABKVSH',
  },
  {
    label: 'Hedera',
    token: 'HBAR',
    wallet: '0.0.779299',
  },
  {
    label: 'Monero',
    token: 'XMR',
    wallet:
      '42HKMWrDzCt45jAp9g5SBe1AJbnBwgUpY8nNfaG6g2T1ZLQpMesUsX1HzZwhuX4CCH7T5ew8AhBPNDXfHNU9xN77N232aap',
  },
  {
    label: 'ICON',
    token: 'ICX',
    wallet: 'hxd07e6d45d9c136a9dcc18eebad937044e36cbc75',
  },
  // {
  //   label: 'EOS.IO',
  //   token: 'EOS',
  //   wallet: '0x00000000000000000000000000000000000000',
  //   // amounts: [0.25, 0.5, 1, 3, 5],
  //   // wallets: [
  //   //   {
  //   //     label: 'Math Wallet',
  //   //     icon: 'metamask',
  //   //   },
  //   // ],
  // },
  {
    label: 'Casper',
    token: 'CSPR',
    wallet:
      '0202aacf42dddd932d76ca61e5bada656e27d226245cea1629caabd4211e90b1abc7',
  },
  {
    label: 'DigitalBits',
    token: 'XDB',
    wallet: '0x165CD37b4C644C2921454429E7F9358d18A45e14',
  },
  // {
  //   label: 'NEO N3',
  //   token: 'NEO',
  //   wallet: 'NVuRiQwtTpDJb2EwKEcjffuxe7K8RzEbes',
  // },
]

export default DATA
