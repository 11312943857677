import { ApiPromise, WsProvider } from '@polkadot/api'
import Provider from './Provider'
import formatTokens from './formatTokens'

const Polkadot = async (address, amount) => {
  if (typeof window !== 'undefined') {
    const p = Provider()

    const enable = await p.web3Enable('donate ukraine')

    if (enable.length) {
      const wsProvider = new WsProvider('wss://rpc.polkadot.io')
      const api = await ApiPromise.create({ provider: wsProvider })

      const allAccounts = await p.web3Accounts()

      const injector = await p.web3FromSource(allAccounts[0].meta.source)

      // send tokens
      const transfer = api.tx.balances.transfer(address, formatTokens(amount))

      transfer
        .signAndSend(
          allAccounts[0].address,
          { signer: injector.signer },
          ({ status }) => {
            if (status.isInBlock) {
              console.log(
                `Completed at block hash #${status.asInBlock.toString()}`
              )
            } else {
              console.log(`Current status: ${status.type}`)
            }
          }
        )
        .catch((error) => {
          console.log(':( transaction failed', error)
        })
    }
  }
}

export default Polkadot
