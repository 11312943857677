/* eslint-disable camelcase */
import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container } from 'react-bootstrap'
import cn from 'classnames'

import Tabs from '../UI/Tabs'
import Icon from '../Icon'

import * as s from './Breakdown.module.scss'

const Item = ({ title, amount, amount_label }) => (
  <div className={s.item__cat}>
    <h3>{title}</h3>
    <small>
      {amount
        ? `${amount.toLocaleString('en-US')} ${amount_label || 'items'}`
        : amount_label}
    </small>
  </div>
)

const Breakdown = () => {
  const { breakdown } = useStaticQuery(graphql`
    {
      breakdown: allBreakdownJson {
        edges {
          node {
            month: date(formatString: "MMMM")
            day: date(formatString: "MMM D")
            items {
              type
              icon
              title
              items {
                title
                amount
              }
              amount
              amount_label
              total
            }
          }
        }
      }
    }
  `)

  const [activeTab, setTab] = useState(1)

  const totalAll = breakdown.edges.map(({ node }) =>
    node.items.reduce((acc, { total }) => acc + total, 0)
  )

  return (
    <Container as="section" id="report" className={s.breakdown}>
      <div className={s.breakdown__inner}>
        <h2 className="h1">Your crypto saves Ukraine</h2>

        <Tabs
          labels={breakdown.edges.map(({ node: { month } }) => month)}
          onClick={setTab}
          active={activeTab}
          className={s.tabs}
        >
          <div className={s.grid}>
            {breakdown.edges[activeTab].node.items.map(
              ({ type, icon, title, items, amount, amount_label, total }) => (
                <div
                  className={cn(s.item, type && s[type])}
                  key={title || icon}
                >
                  {items ? (
                    <div className={s.item__cats}>
                      {items.map((item) => (
                        <Item key={item.title} {...item} />
                      ))}
                    </div>
                  ) : (
                    <Item {...{ title, amount, amount_label }} />
                  )}

                  <div className={s.item__total}>
                    $ {total.toLocaleString('en-US')}
                  </div>

                  <div className={s.item__icon}>
                    <Icon name={icon} size={64} />
                  </div>
                </div>
              )
            )}
            <div className={s.total}>
              <span>
                Total expenses{' '}
                <span>({breakdown.edges[activeTab].node.day})</span>
              </span>
              <span>$ {totalAll[activeTab].toLocaleString('en-US')}</span>
            </div>
          </div>
        </Tabs>
      </div>
    </Container>
  )
}

export default Breakdown
