import {
  SolletExtensionWalletAdapter,
  SlopeWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  SolongWalletAdapter,
} from '@solana/wallet-adapter-wallets'

import {
  SystemProgram,
  Transaction,
  PublicKey,
  clusterApiUrl,
  Connection,
  LAMPORTS_PER_SOL,
} from '@solana/web3.js'

function solToLamports(amount) {
  if (Number.isNaN(amount)) return Number(0)
  return Number(amount * LAMPORTS_PER_SOL)
}

export default async (walletName, amount, walletAddress) => {
  const lamports = solToLamports(amount)

  let adapter
  const API_ENDPOINT = clusterApiUrl('mainnet-beta')
  const connection = new Connection(API_ENDPOINT)

  const phantomWallet = new PhantomWalletAdapter()
  const solong = new SolongWalletAdapter()
  const solflare = new SolflareWalletAdapter()
  const solletExtension = new SolletExtensionWalletAdapter()
  const slope = new SlopeWalletAdapter()

  if (typeof window !== 'undefined') {
    try {
      switch (walletName) {
        case 'Phantom':
          if ('solana' in window && !window.solana.isMathWallet) {
            const phantom = window.solana
            if (phantom.isPhantom) {
              await phantomWallet.connect()
              adapter = phantomWallet
            }
          } else {
            window.open(phantomWallet.url, '_blank')
          }
          break

        case 'Solflare':
          if ('solflare' in window) {
            await solflare.connect()

            adapter = solflare
          } else {
            window.open(solflare.url, '_blank')
          }
          break

        case 'Sollet (Extension)':
          if ('sollet' in window) {
            await solletExtension.connect()

            adapter = solletExtension
          } else {
            window.open(solletExtension.url, '_blank')
          }
          break

        case 'Solong':
          if ('solong' in window) {
            await solong.connect()

            adapter = solong
          } else {
            window.open(
              'https://chrome.google.com/webstore/detail/solong/memijejgibaodndkimcclfapfladdchj',
              '_blank'
            )
          }
          break

        case 'Slope':
          if (window.Slope) {
            await slope.connect()

            adapter = slope
          } else {
            window.open(slope.url, '_blank')
          }
          break
        default:
      }
    } catch (err) {
      console.log(err)
    }
  }

  const to = new PublicKey(walletAddress)

  try {
    if (adapter.publicKey) {
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: adapter.publicKey,
          toPubkey: to,
          lamports,
        })
      )

      transaction.feePayer = await adapter.publicKey
      const blockhashObj = await connection.getRecentBlockhash()
      transaction.recentBlockhash = await blockhashObj.blockhash

      if (transaction) {
        console.log('Txn created successfully')
      }

      // Request creator to sign the transaction (allow the transaction)
      const signed = await adapter.signTransaction(transaction)
      const signature = await connection.sendRawTransaction(signed.serialize())
      await connection.confirmTransaction(signature)
      console.log('Signature: ', signature)
    }
  } catch (err) {
    console.log(err)
  }
}
