import { u8aEq } from '@polkadot/util'
import { decodeAddress, encodeAddress } from '@polkadot/util-crypto'

export default () => {
  if (typeof window === 'undefined') return
  const win = window // don't clobber the existing object, but ensure non-undefined

  function documentReadyPromise(creator) {
    return new Promise((resolve) => {
      if (document.readyState === 'complete') {
        resolve(creator())
      } else {
        window.addEventListener('load', () => resolve(creator()))
      }
    })
  }

  win.injectedWeb3 = win.injectedWeb3 || {} // true when anything has been injected and is available

  function web3IsInjected() {
    return Object.keys(win.injectedWeb3).length !== 0
  } // helper to throw a consistent error when not enabled

  function throwError(method) {
    throw new Error(
      `${method}: web3Enable(originName) needs to be called before ${method}`
    )
  } // internal helper to map from Array<InjectedAccount> -> Array<InjectedAccountWithMeta>

  function mapAccounts(source, list, ss58Format) {
    return list.map(({ address, genesisHash, name, type }) => {
      const encodedAddress =
        address.length === 42
          ? address
          : encodeAddress(decodeAddress(address), ss58Format)
      return {
        address: encodedAddress,
        meta: {
          genesisHash,
          name,
          source,
        },
        type,
      }
    })
  } // have we found a properly constructed window.injectedWeb3

  let isWeb3Injected = web3IsInjected() // we keep the last promise created around (for queries)

  let web3EnablePromise = null

  function getWindowExtensions(originName) {
    return Promise.all(
      Object.entries(win.injectedWeb3).map(([name, { enable, version }]) =>
        Promise.all([
          Promise.resolve({
            name,
            version,
          }),
          enable(originName).catch((error) => {
            console.error(`Error initializing ${name}: ${error.message}`)
          }),
        ])
      )
    )
  } // enables all the providers found on the injected window interface

  function web3Enable(originName, compatInits = []) {
    if (!originName) {
      throw new Error(
        'You must pass a name for your app to the web3Enable function'
      )
    }

    const initCompat = compatInits.length
      ? Promise.all(compatInits.map((c) => c().catch(() => false)))
      : Promise.resolve([true])
    web3EnablePromise = documentReadyPromise(() =>
      initCompat.then(() =>
        getWindowExtensions(originName)
          .then((values) =>
            values
              .filter((value) => !!value[1])
              .map(([info, ext]) => {
                // if we don't have an accounts subscriber, add a single-shot version
                if (!ext.accounts.subscribe) {
                  // eslint-disable-next-line no-param-reassign
                  ext.accounts.subscribe = (cb) => {
                    ext.accounts.get().then(cb).catch(console.error)
                    return () => {
                      // no ubsubscribe needed, this is a single-shot
                    }
                  }
                }

                return { ...info, ...ext }
              })
          )
          .catch(() => [])
          .then((values) => {
            const names = values.map(
              ({ name, version }) => `${name}/${version}`
            )
            isWeb3Injected = web3IsInjected()
            console.log(
              `web3Enable: Enabled ${values.length} extension${
                values.length !== 1 ? 's' : ''
              }: ${names.join(', ')}`
            )
            return values
          })
      )
    )
    return web3EnablePromise
  } // retrieve all the accounts across all providers

  async function web3Accounts({ accountType, ss58Format } = {}) {
    if (!web3EnablePromise) {
      return throwError('web3Accounts')
    }

    const accounts = []
    const injected = await web3EnablePromise
    const retrieved = await Promise.all(
      // eslint-disable-next-line no-shadow
      injected.map(async ({ accounts, name: source }) => {
        try {
          const list = await accounts.get()
          return mapAccounts(
            source,
            list.filter(({ type }) =>
              type && accountType ? accountType.includes(type) : true
            ),
            ss58Format
          )
        } catch (error) {
          // cannot handle this one
          return []
        }
      })
    )
    retrieved.forEach((result) => {
      accounts.push(...result)
    })
    const addresses = accounts.map(({ address }) => address)
    console.log(
      `web3Accounts: Found ${accounts.length} address${
        accounts.length !== 1 ? 'es' : ''
      }: ${addresses.join(', ')}`
    )
    return accounts
  }
  async function web3AccountsSubscribe(cb, { ss58Format } = {}) {
    if (!web3EnablePromise) {
      return throwError('web3AccountsSubscribe')
    }

    const accounts = {}

    const triggerUpdate = () =>
      cb(
        Object.entries(accounts).reduce((result, [source, list]) => {
          result.push(...mapAccounts(source, list, ss58Format))
          return result
        }, [])
      )

    const unsubs = (await web3EnablePromise).map(
      ({ accounts: { subscribe }, name: source }) =>
        subscribe((result) => {
          accounts[source] = result

          triggerUpdate()
        })
    )
    return () => {
      unsubs.forEach((unsub) => {
        unsub()
      })
    }
  } // find a specific provider based on the name

  async function web3FromSource(source) {
    if (!web3EnablePromise) {
      return throwError('web3FromSource')
    }

    const sources = await web3EnablePromise
    const found = source && sources.find(({ name }) => name === source)

    if (!found) {
      throw new Error(`web3FromSource: Unable to find an injected ${source}`)
    }

    return found
  } // find a specific provider based on an address

  async function web3FromAddress(address) {
    if (!web3EnablePromise) {
      return throwError('web3FromAddress')
    }

    const accounts = await web3Accounts()
    let found

    if (address) {
      const accountU8a = decodeAddress(address)
      found = accounts.find((account) =>
        u8aEq(decodeAddress(account.address), accountU8a)
      )
    }

    if (!found) {
      throw new Error(`web3FromAddress: Unable to find injected ${address}`)
    }

    return web3FromSource(found.meta.source)
  } // retrieve all providers exposed by one source

  async function web3ListRpcProviders(source) {
    const { provider } = await web3FromSource(source)

    if (!provider) {
      console.warn(`Extension ${source} does not expose any provider`)
      return null
    }

    return provider.listProviders()
  } // retrieve all providers exposed by one source

  async function web3UseRpcProvider(source, key) {
    const { provider } = await web3FromSource(source)

    if (!provider) {
      throw new Error(`Extension ${source} does not expose any provider`)
    }

    const meta = await provider.startProvider(key)
    return {
      meta,
      provider,
    }
  }

  // eslint-disable-next-line consistent-return
  return {
    web3ListRpcProviders,
    web3FromAddress,
    web3FromSource,
    web3AccountsSubscribe,
    web3Accounts,
    web3Enable,
    web3UseRpcProvider,
    isWeb3Injected,
    web3EnablePromise,
  }
}
