// extracted by mini-css-extract-plugin
export var breakdown = "dj_N7";
export var breakdown__inner = "dj_P7";
export var grid = "dj_Q7";
export var item = "dj_R7";
export var item__cat = "dj_T7";
export var item__cats = "dj_S7";
export var item__icon = "dj_W7";
export var item__total = "dj_V7";
export var tabs = "dj_L7";
export var tall = "dj_Y7";
export var total = "dj_Z7";
export var wide = "dj_X7";