import React, { useState, useEffect } from 'react'
import { InputGroup, Form, Button } from 'react-bootstrap'
import cn from 'classnames'

import useScroll from '~hooks/useScroll'

import CopyButton from '../CopyButton'
import Icon from '../Icon'

import * as s from './Donate.module.scss'

import DATA from './constants'
import sendEth from '../../wallets/Ethereum/MetaMaskWallet'

import sendSol from '../../wallets/solana/Solana'
import sendEos from '../../wallets/Eos/sendEOS'
import sendUSDT from '../../wallets/tron/sendUSDT'
import sendDot from '../../wallets/Polkadot/Polkadot'

const Donate = () => {
  const STEPS = ['init', 'amount', 'custom', 'wallet']

  const { scrollY } = useScroll()

  const isScrolled = scrollY > 40

  const [shadow, setShadow] = useState(null)

  const [step, setStep] = useState(STEPS[0])
  const [selectedToken, setToken] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [amount, setAmount] = useState(null)

  const scrollToView = () => {
    if (window.outerWidth < 730)
      window.scrollTo(0, document.querySelector('#donate').offsetTop - 80)
  }

  const handleToken = (token) => {
    setToken(token)
    setStep(STEPS[1])
    scrollToView()
  }

  const handleAmount = (val) => {
    if (val === STEPS[2]) {
      setStep(STEPS[2])
    } else if (typeof val === 'number') {
      setAmount(val)
      setStep(STEPS[3])
    }
    scrollToView()
  }

  const handleBack = (currStep) => {
    if (currStep === STEPS[3]) {
      setStep(STEPS[1])
    } else if (step !== STEPS[0]) {
      const currIndex = STEPS.findIndex((el) => el === currStep)
      setStep(STEPS[currIndex - 1])
    }
    setShadow(false)
  }

  const [validated, setValidated] = useState(false)

  const handleCustom = (event) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()

    setValidated(true)

    if (form.checkValidity()) {
      setValidated(false)
      handleAmount(Number(event.target[0]?.value))
      scrollToView()
    }
  }
  const SCROLL_OFFSET = 20

  const handleScroll = (e) => {
    const rect = e.target

    if (
      rect.scrollHeight - SCROLL_OFFSET >=
      rect.offsetHeight + rect.scrollTop
    ) {
      setShadow(false)
    } else {
      setShadow(true)
    }
  }

  const handleSendTokens = (label) => {
    switch (selectedToken.token) {
      case 'BTC':
        break
      case 'ETH':
        sendEth(selectedToken.wallet, amount)
        break
      case 'SOL':
        sendSol(label, amount, selectedToken.wallet)
        break
      case 'EOS':
        sendEos(amount, selectedToken.wallet)
        break
      case 'USDT':
        sendUSDT(amount, selectedToken.wallet)
        break
      case 'DOT':
        sendDot(selectedToken.wallet, amount)
        break
      default:
    }
  }

  const handleEsc = (e) => {
    if ((e.code === 'Escape' || e.key === 'Escape') && step !== STEPS[0]) {
      handleBack(step)
    }
  }

  const [btm, setBtm] = useState(null)

  const handleWindowScroll = () => {
    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    )

    const widget = document.querySelector('#donate')

    const isBottom =
      scrollHeight - window.pageYOffset - 262 - widget.offsetHeight - 40 < 0

    setBtm(isBottom && !btm)
  }

  useEffect(() => {
    window.addEventListener('keydown', (e) => handleEsc(e, step))

    window.addEventListener('scroll', handleWindowScroll)

    return () => {
      window.removeEventListener('keydown', (e) => handleEsc(e, step))
      window.removeEventListener('scroll', handleWindowScroll)
    }
  }, [step])

  return (
    <div
      id="donate"
      className={cn(s.donate, [s[`s_${step}`]], {
        [s.scroll]: isScrolled,
        [s.only_copy]: !selectedToken?.amounts && step === STEPS[1],
        [s.bottom]: btm,
      })}
    >
      <div className={s.donate__inner} onScroll={handleScroll}>
        <h2 className={s.donate__header}>
          {step === STEPS[0] &&
            'Donate crypto to Ukraine to support people in their fight for freedom'}
          {[STEPS[1], STEPS[2]].includes(step) &&
            `Send ${selectedToken.token}${
              selectedToken.type ? ` (${selectedToken.type})` : ''
            } to this address:`}
          {step === STEPS[3] && 'Select wallet for the transaction'}
        </h2>

        <div className={s.donate__content}>
          {step === STEPS[0] && (
            <div className={cn(s.shadow, { [s.hide]: shadow })} />
          )}
          {[STEPS[1], STEPS[2]].includes(step) && (
            <>
              <div className={s.wallet}>
                <Form.Control value={selectedToken?.wallet} readOnly />
                <CopyButton content={selectedToken?.wallet} />
              </div>
              {selectedToken?.amounts && (
                <p className={s.donate__lead}>
                  Or just input the amount and confirm the transaction in your
                  wallet:
                </p>
              )}
            </>
          )}

          {step === STEPS[0] && (
            <ul className={cn(s.list, s.tokens)}>
              {DATA.map((token) => (
                <li key={`${token.token}${token.type}`}>
                  <button
                    type="button"
                    className={s.list__item}
                    data-token={token.token.toLowerCase()}
                    onClick={() => handleToken(token)}
                  >
                    <div className={s.list__label}>
                      {token.label}{' '}
                      <span>
                        ({token.token}
                        {token?.type && ` ${token?.type}`})
                      </span>
                    </div>
                    <Icon name={token.token.toLowerCase()} size={32} />
                  </button>
                </li>
              ))}
            </ul>
          )}

          {step === STEPS[1] && selectedToken?.amounts && (
            <ul className={cn(s.list, s.amount)}>
              {selectedToken.amounts.map((num) => (
                <li key={num}>
                  <button
                    type="button"
                    className={s.list__item}
                    onClick={() => handleAmount(num)}
                  >
                    {num} {selectedToken.token}
                  </button>
                </li>
              ))}
              <li>
                <button
                  type="button"
                  className={s.list__item}
                  onClick={() => handleAmount(STEPS[2])}
                >
                  Other
                </button>
              </li>
            </ul>
          )}

          {step === STEPS[2] && (
            <Form
              className={s.custom}
              noValidate
              validated={validated}
              onSubmit={handleCustom}
            >
              <InputGroup>
                <Form.Control
                  type="text"
                  inputmode="numeric"
                  pattern="[0-9]*[.]?[0-9]+"
                  name="amount"
                  defaultValue={selectedToken?.customAmount}
                  aria-label={`${selectedToken.token} amount`}
                  autoComplete="off"
                  required
                />
                <InputGroup.Text>{selectedToken.token}</InputGroup.Text>
              </InputGroup>
              <Button type="submit">Donate</Button>
            </Form>
          )}

          {step === STEPS[3] && (
            <ul className={s.wallets}>
              {selectedToken.wallets.map(({ label, icon }) => (
                <li key={icon}>
                  <button
                    type="button"
                    className={s.wallets__item}
                    onClick={() => handleSendTokens(label)}
                  >
                    {label}
                    <Icon name={icon} size={32} />
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {step === STEPS[0] ? (
        <svg aria-hidden="true" focusable="false" className={s.defs}>
          <linearGradient
            xmlns="http://www.w3.org/2000/svg"
            id="solGradient"
            x1="2.557674"
            y1="24.57199"
            x2="26.69799"
            y2="-3.01622"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.08" stopColor="#9945FF" />
            <stop offset="0.30" stopColor="#8752F3" />
            <stop offset="0.50" stopColor="#5497D5" />
            <stop offset="0.60" stopColor="#43B4CA" />
            <stop offset="0.72" stopColor="#28E0B9" />
            <stop offset="0.97" stopColor="#19FB9B" />
          </linearGradient>
        </svg>
      ) : (
        <button
          type="button"
          className={s.donate__back}
          onClick={() => handleBack(step)}
        >
          <Icon name="chevron" />
        </button>
      )}
    </div>
  )
}

export default Donate
