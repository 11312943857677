/* eslint-disable import/prefer-default-export */
/**
 * @example
 * numberToStrWithSpaces(123456)
 * // returns '123 456'
 * @param n {number}
 * @returns {string | undefined}
 */
export const numberToStrWithSpaces = (n) =>
  n?.toLocaleString('en-US')?.replace(/,/gm, ' ')
